@font-face {
  font-family: couture;
  src: url(../fonts/couture-bld.otf);
  font-weight: bold;
}
.n-logo {
  width: 55px;
  height: 50px;
  /* background-color: #d1d1d1; */
}
.w-logo {
  width: 85px;
  height: 80px;
  /* background-color: #d1d1d1; */
}

.sloganfi {
  color: #008080;
  font-family: couture;
  /* color: white; */
  /* background-color: #008080; */
  /* background: linear-gradient(0.25turn, #fff, #e7eaf3, #d2d5e6, #bec4dd); */
  /* background: linear-gradient(0.25turn, #fff, #e7eaf3, #d2d5e6); */
}
.slogan {
  color: #343A40;
  background: linear-gradient(0.25turn, #fff, #e7eaf3, #dddfec);
}
/* .custom-nav-bar {
  color: #343A40;
   background: linear-gradient(0.25turn, #fff,hsl(180, 61%, 78%),#008080,#008080, #008080, #008080); 
  background: hsla(180, 98%, 23%, 0.329);
} */
.fa-xlg {
  font-size: 2.333em !important;
  line-height: 1.2em !important;
  vertical-align: -30%;
}
.fa-llg {
  font-size: 1.4em !important;
  padding-inline: 4px;
}
.fa-llg:hover {
  font-size: 1.4em !important;
  padding-inline: 4px;
  color: #008080;
}
.fa-btn-h {
  min-height: 20vh !important;
  min-height: 50vh !important;
}
.noUi-horizontal .noUi-handle {
  background-color: #fff !important;
  border-style: solid;
  border-color: #008080;
  box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.2);
  height: 30px !important;
  width: 30px!important;
  cursor: pointer;
  margin-top: -10px !important;
  outline: none;
}
.noUi-horizontal {
    height: 10px !important;
}
.noUi-connect {
    background: #008080 !important;
    border-radius: 3px ;
    height: 100%;
    width: 100%;
}
.cardSearch {
  background-color: transparent  !important;
  box-shadow: 0px 5px 25px 0px transparent  !important;
}
.cardBody {
  padding-top: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  padding-bottom: 0 !important;
  background-color: white;
  box-shadow: 0 0 0 2px darkcyan; 
  border-radius: 10px;
  color: darkcyan;
}
.adBody {
  padding-bottom: 0 !important;
  background-color: white;
  box-shadow: 0 0 0 2px darkcyan;
  border-radius: 10px;
  color: darkcyan;
}
.adBorder {
  box-shadow: 0 0 0 2px darkcyan; 
  border-radius: 10px;
}
.css-tlfecz-indicatorContainer {
  color: darkcyan !important;
}
.css-yk16xz-control {
  border-color: darkcyan !important;
}
.css-1okebmr-indicatorSeparator {
  background-color: darkcyan !important;
}
.SliderStyled {
  padding-right: 65px;
  padding-left: 35px;
  padding-top: 30px;
  padding-bottom: 10px;
}
.SliderStyledLabel {
  padding-top: 40px;
}
.atout {
  width: 45px;
  height: 45px;

}
.toast {
  max-width: none !important;
  /* overflow: visible !important; */
}
.profilePic {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 4px;
  background-color: white;
}
.react-datepicker {
  z-index: 20000 !important;
}
.react-datepicker-wrapper {
  display: inline !important;
}
.react-datepicker__time-container {
  width: 65px !important;
}
.react-datepicker__time-container .react-datepicker__time {
  width: 64px !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 61px !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  width: 62px !important;
}
.loading-logo {
  position: absolute;
  z-index: 2000000000;
  left: 41%;
  top: 22%;
}
.navbar {
  /* max-height: 60px !important;
  padding-top: 10px !important;
  margin-bottom: 10px !important; */
  /* padding-top: 0.175rem!important; */
  max-height: 60px !important;
  margin-bottom: 10px !important;
}
.navbarNoScroll {
  height: 185px !important;
}
.navbarNoScrollb {
  height: 350px !important;
}
/* .navbar-transparency {
  padding-bottom: 10px !important;
  height: calc(-60px + 100vh) !important;
  margin-top: -15px !important;
} */

/* video promo*/
.video-thumbnail {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.thumbnail {
  width: 100%;
  height: auto;
}
.map-container {
  /* width: 40%; */
  float: left;
  height: calc(-60px + 100vh);
  position: fixed;
  overflow: hidden;
}
.map-container-post {
  height: 100%;
}
/* .beautiful-height-no-res {
  min-height: calc(-252px + 100vh);
}
.beautiful-height-outer {
  min-height: calc(-252px + 100vh);
  
} */
.mrgn{
  margin-top: 7px;
}
.header-fd-search-form {
  background-color: gainsboro;
  padding: 10px;
}
.select_input_search {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}
#advancedmenu {
  z-index: 103;
  margin: 7px auto;
  padding: 6px 3px;
  height: 38px;
  background-color: white;
  border-color: darkcyan !important;
}
#advancedmenu:disabled:hover {
  font-size: 13px;
  color: #66676b;
  margin: 9px auto;
  padding: 6px 3px;
  height: 36px;
  background-color: #f3f3f3;
  border-color:#dee2e6 !important;
  box-shadow: none;
}
#advancedmenu:disabled {
  font-size: 13px;
  color: #66676b;
  margin: 9px auto;
  padding: 6px 3px;
  height: 36px;
  background-color: #f3f3f3;
  border-color: #dee2e6 !important;
}
.make-spacefilter {
  margin-top : -15px !important;
}
.result-content {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f3f3f3;
  /* width: 50%; */
  min-height: 100vh;
  float: right;
  -webkit-overflow-scrolling: touch;
}
.resPostDetail {
  float: left;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 200;
  /* background-color: #008080; */
  padding: 5px;
  color: #FFFFFF;
  font-weight: bold;
}
.resPostDetailBottom {
  float: left;
  position: absolute;
  left: 0px;
  top: 160px;
  z-index: 200;
  /* background-color: #008080; */
  padding: 5px;
  color: #FFFFFF;
  font-weight: bold;
}
.opacityKeys {
  background-color: #008080;
  color: #FFFFFF;
  font-size: 10px;
  padding: 2px;
}
.controlBar {
  margin-inline: 12px !important;
}
.wrapper-search {
  margin-top: 60px;
}
.btn-group.special {
  display: flex;
}
.special .btn {
  flex: 1
}

.fullwidth {
  width: 100%;
  display: block;
  position: relative;
  padding: 90px 0 0 0;
  margin: 50px 0 0 0;
  background-color: #f7f7f7;
}
.fullwidth .headline-box {
  position: absolute;
  transform: translate3d(-50%,-50%,0);
  top: 0;
  margin: 0 auto;
  text-align: center;
  margin-left: 50%;
}
.modal-content {
  width:100% !important;
}
.headline-box {
  display: inline-block;
  font-size: 28px;
  line-height: 38px;
  background-color: #fff;
  padding: 22px 50px;
  margin: 0;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.12);
  width: auto;
  border-radius: 3px;
}
.icon-box-1 {
  text-align:center;
  padding:20px 0;
  padding-bottom:15px
}
.icon-box-1.alternative {
  padding:20px;
  padding-bottom:15px
}
.icon-box-1 .icon-container {
  font-size: 66px;
  color: #fff;
  background-color: #008080;
  height: 140px;
  width: 140px;
  line-height: 140px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
    margin-bottom: 0px;
  margin-bottom: 13px;
  display: inline-block;
  position: relative;
}
.icon-box-1 .icon-container .icon-links {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(0) scale(1.2);
  transition: all .2s ease-in;
  display: block;
}
.icon-box-1:hover .icon-container .icon-links {
  opacity: 1;
  visibility: visible;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(0) scale(1.2);
  transition: all .2s ease-in;
  display: block;
}
.icon-box-1:hover .icon-container i {
  opacity: 0;
  transform: translateZ(0) scale(1.16);
}
.icon-box-1 .icon-container i {
  opacity: 1;
  transform: translateZ(0) scale(1);
  transition: all .2s ease-in;
  display: inline-block;
}
.icon-box-1 .icon-container .icon-links a {
  display: block;
  color: #fff;
  padding: 5px 20px;
  transition: all .2s;
  position: relative;
}
.headline.centered {
  text-align: center;
}
h3.headline {
  font-size: 28px;
  line-height: 38px;
  margin: 0 0 30px;
}
.btn-icon-property {
  height: 2.3rem !important;
}
.headline span {
  color: #888;
  font-size: 18px;
  display: block;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-topr-typo {
  /* margin-top: 13rem !important; */
  margin-top: 13rem !important;
  padding-bottom: 4rem;
}
.img-box {
  height: 350px;
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 90;
  margin: 10px 0;
  border-radius: 3px;
  
}
.img-box-background {
  width: 100%;
  height: 100%;
  transition: all .55s;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  transition: transform .35s ease-out;
}
.img-box:hover .img-box-background {
  transform:scale(1.06)
}
.img-box:before {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:block;
  content:"";
  background-color:#222;
  opacity:.3;
  z-index:99;
  transition:.3s ease-out
}
.img-box:hover:before {
background-color:#66676b;
opacity:.7
}
.img-box-content {
  position: absolute;
  z-index: 101;
  top: 50%;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  transform: translateY(-50.5%);
}
.img-box-content h4 {
  font-size: 28px;
  line-height: 36px;
  color: #fff;
  margin: 0;
}
.wide-col {
  width: 100%;
  padding: 0;
  bottom: 0;
}
.double-column-only {
  width: 750px;
  -webkit-box-flex: 0;
  flex: 0 0 750px;
}
.basic-multi-select-annoncer {
  z-index: 1001;
}
.modal-backdrop {
  background: #000 !important;
}
.modal-backdrop.show {
  opacity: .8;
}
.basic-multi-select {
  z-index: 1000;
}
.basic-multi-select-lvl-1 {
  z-index: 999 !important;
}
.basic-multi-select-lvl-2 {
  z-index: 998 !important;
}
.basic-dropdown-ontop {
  z-index: 10001 !important;
}
.badge {
  position: relative;
  z-index: 500 !important;
}
.basic-multi-select-bis {
  z-index: 999;
}
.basic-multi-select-equip {
  z-index: 998;
}
.search-page-map-container {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 100%;
  display: -webkit-box;
  display: flex;
  flex-direction: column;
}
.restoreStyle {
  clear:both;
}
.simulBiat {
  width: 100px;
  height: 35px;
}
.colorBiat {
  color: #093066;
  font-weight: bold;
}
.colorBiat:hover {
  color: #d88400;
  font-weight: bold;
}
.fidari-upload {
  position: relative;
  border-top: dotted #17a2b8;
  border-right: dotted #17a2b8;
  border-left: dotted #17a2b8;
}
.fidari-upload-miniature{
  margin-top: -17px !important;
  border-bottom: dotted #17a2b8;
  border-right: dotted #17a2b8;
  border-left: dotted #17a2b8;
  min-height: calc(100% - 40px);
}
.APosRel {
  position:relative;
  float:left;}
.AClass{
  top:4px;
  left:56px;
  height: 20px;
  width: 20px;
  position: absolute;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 0;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  background-color: red !important;
}
.custom-control-label {
  color: #282a2b;
  line-height: 1.6 !important;
  font-size: 1.2em !important;
}
.info_simule{
  line-height: 1em;
  font-weight: 300;
  font-size: 1.4em;
  margin-top: 0;
  margin-bottom: 0;
}
.makecursor {
  cursor: pointer;
}
.dontmakecursor {
  cursor: default;
}
.map_annonce_crea {
  min-height: 100%;
  /* width: 37vw; */
}
.fa_social_icon {
  font-size: 25px !important;
  /* position: relative;
  top: 3px;
  text-align: center;
  width: 21px; */
}
.HPventeApp {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 1em !important;
}
.HPventeApp a {
  text-decoration: underline;
}
.footerback {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
}
.footer {
  padding: 24px 0;
  margin-left: -15px;
  margin-right: -15px;
}

.pt-10 {
  padding-top: 6rem !important;
}
.pt-20 {
  padding-top: 12rem !important;
}
/*	Détail annonce	*/

/* .image-gallery-image img {
	box-sizing: border-box;
	position: relative;
	display: flex;
	-moz-box-pack: center;
	justify-content: center;
	height: 500px;
	overflow: hidden;
	max-height: calc(-275px + 100vh);
	min-height: 275px;
	width: 100%;
}
.image-gallery-thumbnail img {
	height: 80px;
	width: 100px;
}
.image-gallery-content {
	height: 90%;
	width: 90%;
}
.image-gallery-content.fullscreen {
	height: 80%;
	width: 80%;
} */

/*	annonce recherche	*/
.annonce_result {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  width: 100%;
  height: auto;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.textarea-demand {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  max-height: 100px !important;
}
.textarea-description {
  min-height: 250px !important;
  resize: block !important;
}
.index-page .page-header {
  height: 140vh !important;
}
.index-page-cgu{
  min-height: 140vh !important;
}
.page-header-register {
  min-height: 20vh;
  max-height: 999px;
  padding: 0;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}
.page-header-cgu {
  overflow: visible !important;
  max-height: none !important;
  min-height: 140vh !important;
}
.page-header > .container {
  z-index: 2;
  padding-top: 24vh !important;
  padding-bottom: 40px;
}
.b-menu-height {
  /* min-height: 70vh; */
  min-height: calc(-129px + 100vh);
}
.b-annonce-card {
  min-width: 300px;
}
.b-annonce-card-crop {
  width: 349px;
  height: 195px;
  overflow: hidden;
}
.b-annonce-card-crop img {
  max-width: none !important;
  width: 527px;
  height: 290px;
  margin: -86px 0 0 -86px;
}
.card-sans-img-top {
  height: 405px !important;
  margin: -86px 0 -114px -83px !important;
  padding-top: 73px;
}
.b-annonce-card-body {
  height: 310px;
}
.b-annonce-y-desc {
  height : 101px;
}
.fix-height {
  line-height: 28px !important;
}
#tooltip-top > .tooltip-inner {
  background-color: #000 !important;
  color: #fff;
  border: 1px solid #062e56;
}

#tooltip-top > .tooltip-arrow {
  border-top: 5px solid #062e56;
}
.hoverFav:hover {
  background-color: warning;
}
.notification {
  position: absolute;
  background-color: #fb404b;
  text-align: center;
  border-radius: 10px;
  min-width: 18px;
  padding: 0 5px;
  height: 18px;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
  line-height: 18px;
  top: -3px;
  left: 4px;
}

.notif-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 19px !important;
  font-size: inherit;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ///////////////////////////Biens////////////////////// */
.tab-fiche-bien {
  background-color: #f1f2f7;
  border: 4px solid #fff;
  padding: .75rem;
  vertical-align: top;
}
.tab-fiche-bien-simule {
  vertical-align: top;
}
.table-bordered td, .table-bordered th {
  border: 4px solid #fff;
}

/* ///////////////////////////Icons////////////////////// */
.cc-selector input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.visa {
  background-image: url(../img/property.png);
}
.mastercard {
  background-image: url(../img/apartment.png);
}
.maisondhote {
  background-image: url(../img/hotel.png);
}
.terrain {
  background-image: url(../img/area.png);
}

.cc-selector input:active + .drinkcard-cc {
  opacity: 0.9;
}
.cc-selector input:checked + .drinkcard-cc {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}
.drinkcard-cc {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 120px;
  height: 120px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  /* -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
     -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
          filter: brightness(1.8) grayscale(1) opacity(.7); */
}
.addbien {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 200px;
  height:200px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  /* -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
     -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
          filter: brightness(1.8) grayscale(1) opacity(.7); */
}
.addbien:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
}
.drinkcard-cc:hover {
  -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
}
.modal-lg,
.modal-xl {
  max-width: 859px;
}
.promoteur-immo {
  background-image: url(../img/promoteur.png);
}
.agence-immo {
  background-image: url(../img/agence-immo.png);
}
.maison-hote {
  background-image: url(../img/maison-hote.png);
}
.particulier {
  background-image: url(../img/particulier.png);
}
.border-fidari {
  border: 2px solid #008080 !important;
  border-color: #008080 !important;
}
.fixLineHeight {
  line-height: 0 !important;
}
.fullwidthModal {
  width: 100% !important;
}
.fullwidthMenu {
  flex: max-content;
}
.fullwidthMenuLink {
  min-width: 100% !important;
  border-radius: .25rem .25rem 0 0 !important;
}
.onglet-height {
  line-height: 30px !important;
}
.gridProducts_box > ul.nav.nav-pills {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.gridProducts_box > ul.nav.nav-pills > li {
  float: none;
  display: table-cell;
}
.gridProducts_box > ul.nav.nav-pills > li > a {
    text-align: center;
}
.gridProducts_box__items {
  width: 100%;
}
.size09em {
  font-size: 0.9em !important;
}
/* ///////////////////////////////////////////////////////////// */
@media (max-width: 991px) { 
  .navbar {
    padding-top: 6px !important;
    margin-top: -10px !important;
    max-height: 70px !important;
    margin-bottom: 10px !important;
  }
}
/* @media (max-width: 767) { 
  .navbar-transparency {
    padding-bottom: 10px !important;
    height: calc(-60px + 100vh) !important;
    margin-top: -15px !important;
  }
} */
@media (min-width: 768px) { 
  .navbar-transparency {
    padding-bottom: 30px !important;
    min-height: 140px !important;
  }
  .pullLsearch {
    left: -5%;
    margin-top: 50px;
  }
  .roomsLeft{
    margin-left : 15px !important;
  }
}

.invalid-feedback2 {
width: 100%;
margin-top: .25rem;
font-size: 80%;
color: #dc3545;
}
.numb {
  width: 30px;
  text-align: center;
  vertical-align: bottom;
  padding-top: 8px;
}
/* ///////////////////////////////////// */
.widget-content {
  padding: 1rem;
  flex-direction: row;
  align-items: center;
}


.widget-content .widget-content-wrapper {
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
}
.text-white {
  color: #fff !important;
}
.bg-midnight-bloom {
  background-image:linear-gradient(to top, #434343 0%, #008080 100%) !important
  
}
.bg-arielle-smile {
  background-image:  linear-gradient(to top,  #8c6611 0%, #9fa037 100%) !important;}

.bg-grow-early {
  background-image: linear-gradient(to top, #194733 0%, #3cba92 100%) !important;
}
.widget-content .widget-content-left .widget-heading {
  opacity: .8;
  font-weight: bold;
}
.widget-content .widget-content-left .widget-subheading {
  opacity: .5;
}
.widget-content .widget-numbers {
  font-weight: bold;
  font-size: 1.8rem;
  display: block;
}

.widget-content .widget-content-right {
  margin-left: auto;
}
.widget-font {
  font-family: "Source Sans Pro","Trebuchet MS",serif;
font-size: 18px;
}
@keyframes transition {
  0%   { margin-left: -70px;opacity:0; }
  100% { margin-left: 0;opacity:1; }
  }
  .widget-content  {opacity:0;animation: transition 1s ease-in-out 0.25s forwards;}

  @keyframes transition {
    0%   { margin-left: -70px;opacity:0; }
    100% { margin-left: 0;opacity:1; }
    }
    .widget-font  {opacity:0;animation: transition 0.5s ease-in-out 0.15s forwards;}


    .widget-content {
    
      transition: width 0.3s;
    }
    
    .widget-content:hover {
      width: 105%;
    }
    .widget-font {
    
      transition: width 2s;
    }
    
    .widget-font:hover {
      background-color: #e7eaf3;
    }
  /* ///Filtre Recherche//////////////////////////// */
    .btn-sm-transaction {
      height: 37px; 
      font-size: 15px;
      border-radius: 0.1875rem;
      padding: 5px 9px;
      white-space: nowrap;
    }
    .btn-group {
      margin-top: -1px;
    }  
/* //////////////////////////////////////////// */

.myselect{
  position: absolute;
  /* left: 183px;
  top: 105px; */
  background: #fff;
  border-radius: 8px;
  border: 1px solid #008080;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  /* display: block; */
  z-index: 1001;
  width: 300px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 10px;
}
.myselect2{
  position: absolute;
  /* top: 105px; */
  left: -75px; 
  background: #fff;
  border-radius: 8px;
  border: 1px solid #008080;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  display: block;
  z-index: 1001;
  width: 250px;
  padding: 10px;
}
.myselect3{
  position: absolute;
  /* top: 105px;
  left: 520px; */
  background: #fff;
  border-radius: 8px;
  border: 1px solid #008080;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  display: block;
  z-index: 1001;
  width: 250px;
  padding: 10px;
}
.btnfiltre{
  background-color: #008080!important;
  border-radius: 8px!important;
}

.pos{
  position: relative;
}
#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}
.pagination .page-item.active > .page-link {
  background-color: #008080 !important;
  border-color: #888888;
  color: #FFFFFF;
}
.pagination .page-item .page-link {
  text-transform: none !important;
  border: 0;


  border-radius: 20px !important;
  transition: all .3s;
  padding: 0px 1px  !important;
  margin: 0 3px  !important;
  min-width: 24px !important;
  text-align: center;
  box-shadow: none;
  height: 30px;
  line-height: 30px;
  color: #2c2c2c;
  cursor: pointer;
  font-size: 13px !important;
  background: transparent;
}

.text-alert{
  color: #008080!important;

}
.text-alert:hover{
  color: #000000!important;

}
.list-critere{
  background-color: #f2ede7 !important;
  /* height: 110px  !important; */
  /* width: 100%  !important; */
  border-radius: 5px  !important;
  padding: 15px;

}
.input-email{
  border: 1px solid #d3c9c9 !important;
  border-radius: 0.1875rem !important;
  color: #2c2c2c ;
  height: 42px!important;
}
.alert-btn{
margin: 0 0 0 0 !important;
/* border-radius: 10px !important; */
background-color:#008080!important;
}
.modal-content {
  border-radius: 0.1875rem!important ;
  /* border-color: #da6f05; */
  border: 4px solid #008080 !important;
}